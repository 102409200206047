import config from "../config";
import apiClient from "../common/http/http-tenant";

export default async function sendErrorLogsToServer(errorLog) {

  const code = errorLog?.status === "" ? 0 : errorLog?.status;
  const errorLine = 0;
  const errorFile = errorLog?.fileName ? errorLog.fileName : " ";

  const headers = {
    "Content-type": "application/json",
    "x-api-key": config.apiRootKeyTenant,
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("jwt"))["token"]
      }`,
  };
  const brokerURL = window.location.href;
  const splitedURL = brokerURL?.split("/");

  const errorLogPayload = {

    broker: splitedURL[3],
    userId: JSON.parse(sessionStorage.getItem('jwt'))?.id ?? " ",
    mobile: errorLog["mobile"] ?? " ",
    email: errorLog["email"] ?? " ",
    // errorCode: code,
    errorMessage: errorLog?.message,
    // pageName: errorFile,
    // lineNumber: errorLine,
    loggedinID: errorLog["loggedinID"] ?? " ",
    portfoliId: errorLog["portfoliId"] ?? " ",
    URL: errorLog["URL"] ?? " ",
    method: errorLog["method"] ?? " ",
    JWT: JSON.parse(sessionStorage.getItem('jwt'))?.token ?? " ",
    browserName: errorLog["browserName"] ?? " ",
    dateTime: errorLog["dateTime"] ?? " ",
    browserVersion: errorLog["browserVersion"] ?? " ",
  }

  const truncatedPageName = errorFile.substring(0, 50);

  const response = await apiClient.post(
    "/client/Log",
    JSON.stringify({
      errorCode: code,
      errorMessage: JSON.stringify(errorLogPayload),
      pageName: truncatedPageName,
      lineNumber: errorLine,
    })
    ,
    { headers }
  );
  //console.log(response);
}
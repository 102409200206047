import React from 'react';
// import '../../assets/css/customer/scss/main.scss';

const Loader = (props) => {
    return (
        <React.Fragment>

            <div className={`portfolio-section loader-popup mb-loader ${props.customClass !== undefined ? props.customClass : ''}`}>
                <div className="row">
                    <div className="container-loader mb-loader">
                        <div className="l">L</div>
                        <div className="circle"></div>
                        <div className="square"></div>
                        <div className="triangle"></div>
                        <div className="i">I</div>
                        <div className="n">N</div>
                        <div className="g">G</div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default Loader;

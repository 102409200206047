import axios from "axios";
import config from "../../config";

export default axios.create({
  baseURL: config.apiRootUrl,
  headers: {
    "Content-type": "application/json",
    "x-api-key": config.apiRootKeyInvestor,
  },
});

// export default axios.create({
//   baseURL: "https://sandbox.deseo.tech:443/v1",
//     headers: {
//     "Content-type": "application/json",
//     "x-api-key": "7744c1f3-35c2-4ea9-9c28-93b4aabe25fc"
//   }
// });

import apiClient from '../../../common/http/http-investor';
import config from "../../../config.js";
import { errorCodeHandling } from '../../../utility/commonErrorCodeHandling';

export async function getDashboardListAPI(subscriptionRequest) {
    try {
        const { requestToken, clientId, take, bookmark } = subscriptionRequest;
        const response = await apiClient.get(`/client/${clientId}/subscription?take=${take}&bookmark=${bookmark}`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}


export async function getRiskProfileListAPI(riskProfileRequest) {
    try {
        const { requestToken, clientId, take, bookmark } = riskProfileRequest;
        const response = await apiClient.get(`/client/${clientId}/serviceType/${config.serviceType}/riskprofile?take=${take}&bookmark=${bookmark}`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}

export async function getOrderBookListAPI(requestToken, clientID) {
    try {
        const response = await apiClient.get(`/client/${clientID}/investment/orders`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}

export async function getTradeBookListAPI(tradeBookRequest) {
    try {
        const { requestToken, clientId, take, bookmark } = tradeBookRequest;
        const response = await apiClient.get(`/client/${clientId}/investment/order/portfolios?take=${take}&bookmark=${bookmark}`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);

    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);
    }
}

export async function getTradeBookDetailsListAPI(tradeBookOrderDetailsRequest) {
    try {
        const { requestToken, clientId, orderId } = tradeBookOrderDetailsRequest;

        //Recently on UI Amit changed order book to trade book
        const response = await apiClient.get(`/client/${clientId}/investment/order/${orderId}/orderdetails`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);
    }
}

export async function getInvestmentListAPI(investmentRequest) {
    try {
        const { requestToken, clientId, take, page } = investmentRequest;
        const response = await apiClient.get(`/client/${clientId}/investment?holding=false&take=${take}&page=${page}`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}

export async function getPortFolioDataDetailsAPI(portFolioDataDetailsRequest) {
    try {
        const { requestToken, clientId, portfolioId } = portFolioDataDetailsRequest;
        const response = await apiClient.get(`/client/${clientId}/investment/portfolio/${portfolioId}/holding`, {
            headers: {
                "Authorization": `Bearer ${requestToken}`
            },
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}

export async function getSubDomainExistAPI(subDomain) {
    const params = {
        domain: subDomain,
    };
    try {
        const response = await apiClient.get(`/public/advisor/domain/exists`, {
            params
        });
        return errorCodeHandling(response);
    } catch (error) {

        return errorCodeHandling(error?.response, [error?.config?.method, `${error?.config?.baseURL}${error?.config?.url}`]);;
    }
}
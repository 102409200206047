import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ErrorModalContextProvider } from './store/errorModalContext';
import ErrorBoundary from "./utility/catchUnexpectedErrors"

ReactDOM.render(
  <React.StrictMode>
    <ErrorModalContextProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ErrorModalContextProvider>

    {/* <Loader /> */}
  </React.StrictMode>,
  document.getElementById("root")
);